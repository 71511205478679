import { Simulator } from "Models";
import { TbUpload } from "react-icons/tb";
import { BsChatText } from "react-icons/bs";
import { useAppDispatch } from "utils/hooks";
import { IoPlayOutline } from "react-icons/io5";
import { IoImageOutline } from "react-icons/io5";
import { modalAction } from "ui-modules/modals/modalSlice";
import { modalName, ModalType } from "utils/modals/modalHelpers";
import { leagueQueryDefaultOptions } from "modules/leagues/leagueHelpers";
import {
  getUserIdFromToken,
  useFacilitiesByUserId,
  useGetCurrentLocalFacilityId,
} from "modules/facility/facilityHooks";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import styles from "../SingleFacilityControlDashboard.styles";
import SimEndRoundButton from "components/shared/control/SimEndRoundButton";

const SingleSimCardViewFooter: React.FC<SingleSimCardViewFooterProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const { simulator, roundInProgress, stateIsActive, roundIsActive } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const userId = () => getUserIdFromToken();

  const [, , refreshFacilities] = useFacilitiesByUserId(userId());

  const moveRoundModalName = modalName(ModalType.MoveRoundModal, simulator.id);
  const disableMoveRoundButton = !stateIsActive || !roundInProgress;

  const sendSimMessageModalName = modalName(
    ModalType.SendSimulatorMessage,
    simulator.id
  );

  const setModal = (modalName: string) =>
    dispatch(modalAction({ isOpen: true, modalName }));

  const setLiveScreenshots = (liveScreenshot: boolean) => {
    const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${currentFacilityId}/simulators/screenshots`;

    axios
      .patch(
        url,
        [{ id: simulator.id, screenShotOn: liveScreenshot }],
        leagueQueryDefaultOptions()
      )
      .then(async () => {
        await refreshFacilities();
      });
  };

  return (
    <>
      <div
        className={`${styles.SingleSimCardViewFooterContainer} rounded-bl-xl`}
      >
        {roundInProgress ? (
          <SimEndRoundButton
            simulatorId={simulator.id}
            className={`${roundIsActive ? "disabled" : ""}  p-2 h-8 w-8`}
            showIcon={true}
            showText={false}
          />
        ) : (
          <button
            onClick={() => setModal(`roundControlModal-${simulator.id}`)}
            data-tip="Load Round"
            data-for={`singleSimCardViewFooter-${props.simulator.id}`}
            className={`${roundIsActive ? "disabled:cursor-not-allowed" : ""} `}
          >
            <IoPlayOutline className={styles.SingleSimCardViewFooterButton} />
          </button>
        )}
      </div>
      <div className={styles.SingleSimCardViewFooterContainer}>
        <button
          onClick={() => setModal(sendSimMessageModalName)}
          data-for={`singleSimCardViewFooter-${props.simulator.id}`}
          data-tip="Send Sim Message"
        >
          <BsChatText className={styles.SingleSimCardViewFooterButton} />
        </button>
      </div>
      <div className={styles.SingleSimCardViewFooterContainer}>
        <button
          onClick={() => setLiveScreenshots(!simulator.screenShotsOn)}
          data-for={`singleSimCardViewFooter-${props.simulator.id}`}
          data-tip={`Turn live screenshots ${
            simulator.screenShotsOn ? "off" : "on"
          }`}
        >
          <IoImageOutline
            className={`${styles.screenshotFooterButton} ${
              simulator.screenShotsOn
                ? " text-green-700 dark:text-brandYellow border-green-700 dark:border-brandYellow"
                : " brand-gray dark:text-zinc-800"
            }`}
          />
        </button>
      </div>
      <div
        className={`${styles.SingleSimCardViewFooterContainer} rounded-br-xl`}
      >
        <button
          disabled={disableMoveRoundButton}
          onClick={() => setModal(moveRoundModalName)}
          data-tip={
            disableMoveRoundButton ? "Move Round Disabled" : "Move Round"
          }
          data-for={`singleSimCardViewFooter-${props.simulator.id}`}
          className="cursor-pointer disabled:cursor-not-allowed"
        >
          <TbUpload className={styles.SingleSimCardViewFooterButton} />
        </button>
      </div>
      <ReactTooltip
        place="bottom"
        delayShow={200}
        backgroundColor="#f9fafb"
        textColor="#4b5563"
        border={true}
        borderColor="#d1d5db"
        id={`singleSimCardViewFooter-${props.simulator.id}`}
        className="!text-xs"
      />
    </>
  );
};
export default SingleSimCardViewFooter;

type SingleSimCardViewFooterProps = {
  simulator: Simulator;
  roundInProgress?: boolean;
  stateIsActive?: boolean;
  roundIsActive?: boolean;
};
SingleSimCardViewFooter.defaultProps = {
  roundInProgress: false,
  stateIsActive: false,
  roundIsActive: false,
};
