import { createSelector } from "reselect";
import { getCurrentFacility } from "../facility/facilitySelectors";
import { RootState, SimulatorsEntitiesState } from "StoreTypes";
import { FacilityNormalized } from "EntityTypes";
import { Simulator } from "Models";

export const getAllSimulators = (state: RootState): Simulator[] =>
  Object.values(state.entities.simulators);

export const getSimulatorsByCurrentFacility = createSelector(
  getCurrentFacility,
  (state: RootState) => state.entities.simulators,
  (
    currentFacility: FacilityNormalized | undefined,
    simulators: SimulatorsEntitiesState
  ): Simulator[] => {
    if (!currentFacility) return [];
    return currentFacility.simulators.map((simId: string) => simulators[simId]);
  }
);

export const getSimulatorBySimId = (
  state: RootState,
  { simId }: { simId: string }
) =>
  state?.entities?.simulators ? state.entities.simulators[simId] : undefined;

export const getSimulatorsByIdArray = (
  state: RootState,
  ids: string[]
): Simulator[] => {
  if (!state.entities.simulators) return [];
  return Object.values(state.entities.simulators).filter((sim) =>
    ids?.includes(sim.id)
  );
};

export const getScreenshotBySimId = (
  state: RootState,
  { simId }: { simId: string }
) =>
  state?.entities?.screenshots ? state.entities.screenshots[simId] : undefined;
