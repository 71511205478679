export const styles = {
  simulatorSortCardContainer:
    "flex flex-row visible text-opacity-100 bg-white border border-gray-200 text-black text-sm rounded-lg p-4 poppins active:bg-brandYellow z-9999 shadow-sm",
  sortGrab: "flex flex-0 w-8 hover:cursor-grab justify-items-end m-0 p-0",
  button:
    "text-white bg-brandGreen rounded text-xs px-2 py-1 poppins disabled:opacity-50 disabled:cursor-not-allowed",
  spinnerContainer:
    "w-full h-full min-h-64 bg-white bg-opacity-50 z-50 flex items-center justify-center",
  sortedList: "select-none grid auto-cols-auto gap-2",
};
