import { ServiceRequest } from "Models";
import { useSelector } from "react-redux";
import { getSimulatorBySimId } from "modules/simulator/simulatorSelectors";
import { DateTime } from "luxon";
import { RootState } from "StoreTypes";
import { motion } from "framer-motion";
import { ServiceRequestType, MotionVariantType } from "types/enums";
import { useDismissServiceRequest } from "modules/service-requests/serviceRequestHooks";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import { titleCase } from "../../../utils/enums/enumHelper";
import {
  serviceAlertIconColorHelper,
  serviceAlertIconHelper,
} from "utils/icons";
import { createElement } from "react";

type ServiceRequestProps = {
  serviceRequest: ServiceRequest;
};

const ServiceRequestContainer: React.FC<ServiceRequestProps> = (props) => {
  const { serviceRequest } = props;
  const serviceRequestType = serviceRequest?.type || 2;

  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: serviceRequest.simulatorId })
  );

  const [, dismissServiceRequest] = useDismissServiceRequest(serviceRequest.id);

  return (
    <motion.div
      className={`w-full shadow rounded-lg bg-white dark:bg-zinc-800 dark:text-gray-200 mb-4 p-4 notification-box flex cursor-pointer text-xl ${
        serviceRequestType === ServiceRequestType.CC_EVENT_HELP
          ? "border-red-200 border"
          : ""
      } `}
      variants={containerChildMotionVariants}
      initial={MotionVariantType.REST}
      whileHover={MotionVariantType.HOVER}
      whileTap={MotionVariantType.PRESSED}
      whileDrag={MotionVariantType.EXIT}
      drag="x"
      dragConstraints={{ left: 0 }}
      onTap={dismissServiceRequest}
      onDragEnd={dismissServiceRequest}
      key={serviceRequest.id}
    >
      <div className="inline-block col-span-1 w-1/12 items-center mr-2">
        {createElement(serviceAlertIconHelper[serviceRequestType], {
          color: serviceAlertIconColorHelper[serviceRequestType],
          size: "1.2rem",
        })}
      </div>
      <div className="flex flex-col col-span-8 w-full">
        <div className="flex flex-row justify-between items-end">
          <div className="poppins text-sm font-bold text-gray-900 dark:text-gray-200 pt-2">
            {simulator?.name}
          </div>
          <div className="text-xs poppins text-gray-700 dark:text-gray-200">
            {DateTime.fromISO(serviceRequest?.createdDate)?.toRelative()}
          </div>
        </div>
        <div>
          <span className="text-gray-400 text-sm poppins">
            {titleCase(
              ServiceRequestType[serviceRequestType]?.replace("CC_", "")
            )}
          </span>
        </div>
      </div>
    </motion.div>
  );
};
export default ServiceRequestContainer;
