import { Simulator } from "Models";
import { styles } from "./SingleSimListView.styles";
import SingleSimListView from "./SingleSimListView";
import { AnimatePresence, motion } from "framer-motion";
import {
  containerChildMotionVariants,
  containerMotionVariants,
} from "utils/animations/animationHelper";

type ListViewContainerProps = {
  simulators: Simulator[];
  allSelected: boolean;
  callback: any;
};

const ListViewContainer: React.FC<ListViewContainerProps> = (props) => {
  const { simulators, allSelected, callback } = props;

  return (
    <AnimatePresence>
      <motion.div
        variants={containerMotionVariants}
        initial="hiddenY"
        animate="showY"
        className="@container/listview flex flex-col shadow-sm border-t border-l border-r pb-2 dark:border-0 border-gray-200 rounded-xl sm:-mx-6 md:mx-2 px-2 md:px-0 text-gray-600 poppins bg-white dark:bg-zinc-800"
      >
        <motion.div
          className="flex flex-row flex-auto border-gray-200 dark:border-zinc-900"
          variants={containerChildMotionVariants}
          key={`singleSimList-header`}
          id={`singleSimList-header`}
        >
          <div className={`${styles.listViewColumn} pl-8`}>
            <span className="">Name</span>
          </div>
          <div className={`${styles.listViewColumnHideOnSmall}`}>Players</div>
          <div className={`${styles.listViewColumnHideOnSmall}`}>Status</div>
          <div className={`${styles.listViewColumn}`}>Timer</div>
          <div className={`${styles.listViewColumn}`}>Progress</div>
          <div className={`${styles.listViewEditColumn}`}>Action</div>
        </motion.div>
        {simulators?.map((sim) => (
          <SingleSimListView
            simId={sim.id}
            key={`${sim.id}-${allSelected ? "on" : "off"}`}
            checkboxFunction={callback}
            checked={allSelected}
          />
        ))}
      </motion.div>
    </AnimatePresence>
  );
};
export default ListViewContainer;
