import Row from "components/ui/layout/Row";
import { useSimulatorState } from "modules/simulator-state/simulatorStateHooks";
import { timerArrayToTimerString } from "utils/timer/timerHelpers";

type OwnProps = {
  simulatorId: string;
};

const Timer: React.FC<OwnProps> = (props) => {
  const [simulatorState] = useSimulatorState(props.simulatorId);

  return (
    <Row cssClasses="timer items-center content-center z-10 dark:text-gray-200 poppins text-sm font-bold">
      {timerArrayToTimerString(simulatorState?.timerValue)}
    </Row>
  );
};
export default Timer;
