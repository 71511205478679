export const styles = {
  simCardContainer: [
    "w-full",
    "h-full",
    "border",
    "border-gray-200",
    "text-gray-900",
    " bg-white dark:bg-zinc-800 ",
    "dark:border-0",
    "dark:border-transparent",
    "dark:text-gray-200",
    "rounded-xl",
  ].join(" "),
  simHeaderContainer: [
    "border-gray-200  dark:bg-zinc-800  rounded-tl-xl rounded-tr-xl flex flex-row border-gray-200",
  ],
  simHeaderNameContainer: [
    "flex w-full border-b border-gray-200 dark:border-zinc-900 py-1",
  ].join(" "),
  simHeaderName: [
    "w-3/4 p-3 ml-2 text-left font-normal m-auto poppins text-sm truncate",
  ].join(" "),
  checked: ["border border-brandGreen"].join(" "),
  unchecked: ["border border-gray-200"].join(" "),
  active: "text-black bg-white dark:text-gray-200  dark:bg-zinc-800",
  inactive: "text-black bg-white dark:text-gray-200 dark:bg-zinc-800",
  checkbox: [
    "mr-2 mt-2 border-gray-400 rounded-sm disabled:bg-gray-300 text-brandGreen rounded focus:ring-0 ring-opacity-0 border-2 border-gray-500 text-md",
  ].join(" "),
  checkboxLabel: ["dark:text-gray-200 text-md"].join(" "),
  simNameContainer: [
    "py-4 px-4 sm:py-6 sm:px-6 dark:bg-zinc-800 text-gray-500 dark:text-gray-200 h-36",
  ].join(" "),
  simStatus: ["text-xl md:text-2xl text-gray-500 dark:text-gray-200"].join(" "),
  timerStatus: [
    "text-xl md:text-2xl text-gray-500 dark:text-gray-200 float-right",
  ].join(" "),
  singleSimCardViewRateOfPlay: "flex flex-col w-1/2 justify-end m-auto",
  singleSimCardViewBody: "flex flex-col w-3/5 ml-6 3xl:ml-6 mt-4",
  singleSimCardViewSimStatus: "flex flex-row w-full mt-4",
  singleSimCardViewRoundPace: "flex flex-row w-full mt-2",
  singleSimCardViewPlayerRow: "flex flex-row justify-between h-10",
  singleSimCardViewTimerColumn: "flex flex-col w-2/5 mr-4 3xl:mr-6 mt-4 pb-4",
  singleSimCardViewFooterRow:
    "flex flex-row align-bottom h-auto border-t border-gray-200 dark:border-zinc-900 rounded-b-xl",
  SingleSimCardViewFooterContainer:
    "flex-auto text-center text-sm py-3 text-gray-500 dark:text-white dark:bg-zinc-800 disabled:cursor-default disabled:bg-gray-200",
  SingleSimCardViewFooterButton:
    "text-lg p-2 font-normal brand-gray hover:text-gray-700 dark:hover:bg-zinc-400 active:shadow-lg cursor-pointer h-8 w-8 rounded-full border border-gray-200 dark:border-zinc-900 bg-white dark:bg-zinc-500 brand-gray dark:text-black",
  screenshotFooterButton:
    "text-lg p-2 font-normal hover:text-gray-700 dark:hover:bg-zinc-400 active:shadow-lg cursor-pointer h-8 w-8 rounded-full border border-gray-200 dark:border-zinc-900 bg-white dark:bg-zinc-500",
  singleSimCardViewRestartPowerContainer: "flex flex-row gap-1",
  singleSimCardViewRestartButton:
    "flex rounded-full h-6 w-6 text-center items-center text-sm text-blue-500 border-blue-200 shadow-sm hover:shadow-lg hover:text-white bg-blue-50 hover:bg-blue-500 border hover:border-blue-200 dark:hover:bg-zinc-400 dark:bg-zinc-500 dark:border-zinc-900",
  singleSimCardViewPowerButton:
    "flex rounded-full h-6 w-6 text-center items-center text-sm text-red-500 border-red-200 shadow-sm hover:shadow-lg hover:text-white bg-red-50 hover:bg-red-500 border hover:border-red-200 dark:hover:bg-zinc-400 dark:bg-zinc-500 dark:border-zinc-900",
  dateContainer: "grid grid-cols-2 gap-2",
  dateSpan:
    "text-sm poppins font-normal block text-black dark:text-gray-200 pt-4 pb-2",
  datePicker:
    "border border-gray-300 rounded-xl text-sm text-black dark:text-white w-full bg-white dark:bg-zinc-900",
  submit: [
    "w-full text-sm",
    "block",
    "bg-brandYellow",
    "hover:bg-brandGreen hover:text-white",
    "poppins",
    "py-2",
    "px-4",
    "mt-4",
    "rounded-xl",
    "min-w-min",
    "w-full hover:animate-pulse",
    "focus:ring-2",
    "focus:ring-blue-600",
    "active:bg-green-700",
    "disabled:opacity-50",
    "disabled:cursor-not-allowed",
  ].join(" "),
};
export default styles;
