import { Event } from "Models";
import { useMemo, useState } from "react";
import { MotionVariantType } from "types/enums";
import { AnimateSharedLayout, motion } from "framer-motion";
import { useEventsByFacilityId } from "modules/events/eventHooks";
import { dailyQueueContainer } from "utils/animations/animationHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import EventItem from "./EventItem";
import CreateEventButton from "./CreateEventButton";
import ReloadEventsButton from "./ReloadEventsButton";
import { useSelector } from "react-redux";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import { verifySimulatorInstallIds } from "modules/events/eventHelpers";
import CreateEventFormOverlay from "./CreateEventFormOverlay";
import EventItemSkeletonLoader from "./EventItemSkeletonLoader";

const EventsList: React.FC = () => {
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const [selectedEventId, setSelectedEventId] = useState<number | boolean>(
    false
  );
  const [
    facilityEvents,
    facilityEventsStatus,
    refreshFacilityEvents,
  ] = useEventsByFacilityId(currentFacilityId);

  const simulators = useSelector(getSimulatorsByCurrentFacility);
  const validSimulatorInstallIds = useMemo(
    () => verifySimulatorInstallIds(simulators),
    [simulators]
  );

  return (
    <>
      {!validSimulatorInstallIds ? (
        <CreateEventFormOverlay />
      ) : (
        <nav
          className="h-full overflow-y-hidden overflow-x-hidden"
          aria-label="Directory"
        >
          <div className="pb-8 pt-4">
            <CreateEventButton />
            <ReloadEventsButton callback={refreshFacilityEvents} />
          </div>
          <div className="relative">
            <div className="eventListContainer rounded-md w-full">
              <AnimateSharedLayout>
                <motion.ul
                  className="relative z-0 divide-y divide-gray-200 mb-8 w-full"
                  variants={dailyQueueContainer}
                  initial={MotionVariantType.HIDDEN}
                  animate={MotionVariantType.SHOW}
                >
                  {facilityEvents?.length ? (
                    facilityEvents?.map((event: Event) => (
                      <EventItem
                        event={event}
                        key={event?.id}
                        isOpen={selectedEventId === event?.id}
                        callback={() =>
                          setSelectedEventId(
                            selectedEventId === event.id ? false : event.id
                          )
                        }
                      />
                    ))
                  ) : facilityEventsStatus.isPending ? (
                    <>
                      <EventItemSkeletonLoader />
                      <EventItemSkeletonLoader />
                      <EventItemSkeletonLoader />
                      <EventItemSkeletonLoader />
                      <EventItemSkeletonLoader />
                    </>
                  ) : (
                    <>There are no events currently scheduled</>
                  )}
                </motion.ul>
              </AnimateSharedLayout>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};
export default EventsList;
