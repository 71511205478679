import { useSelector } from "react-redux";
import RoundProgressBarSlim from "components/shared/control/RoundProgressBarSlim";
import {
  getScreenshotBySimId,
  getSimulatorBySimId,
} from "modules/simulator/simulatorSelectors";
import { RootState } from "StoreTypes";
import { useSimulatorState } from "modules/simulator-state/simulatorStateHooks";
import styles from "../SingleFacilityControlDashboard.styles";
import ScorecardModal from "./ScorecardModal";
import { createContext, useEffect, useMemo, useState } from "react";
import {
  simulatorStateIsActive,
  isSimulatorOff,
  isActive,
} from "modules/simulator-state/simulatorStateHelpers";
import { isModalOpen, modalName, ModalType } from "utils/modals/modalHelpers";
import SimulatorCardOverlay from "./SimulatorCardOverlay";
import RoundControlModal from "../round-control/RoundControlModal";
import PlayerListIcons from "components/shared/control/PlayerListIcons";
import SimTimerCard from "./timers/SimTimerCard";
import SimStatusCard from "./SimStatusCard";
import AddTimeInnerModal from "./AddTimeInnerModal";
import SingleSimCardViewFooter from "./SingleSimCardViewFooter";
import RateOfPlay from "./RateOfPlay";
import SendMessageInnerModal from "./SendMessageInnerModal";
import { motion } from "framer-motion";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import ShutdownAndRestartButtons from "./ShutdownAndRestartButtons";
import MoveRoundInnerModal from "./MoveRoundInnerModal";
import ScorecardLinks from "./ScorecardLinks";
import { useGetCurrentReservations } from "modules/simulator-reservations/simulatorReservationHooks";
import { DateTime } from "luxon";
import { useAppDispatch } from "utils/hooks";
import { setSelectedQueueItem } from "ui-modules/queue/queueSlice";
import { modalAction } from "ui-modules/modals/modalSlice";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import { SimulatorReservation } from "Models";
import LiveScreenshots from "./LiveScreenshots";

type CardType = {
  cardIcon?: string;
  cardTitle?: string;
  cardStatus?: string;
  cardClass?: string;
  checked?: boolean;
  checkboxFunction?: any;
  simId: string;
};

export const SimCardContext: React.Context<{
  simulatorId: string;
}> = createContext({
  simulatorId: "",
});

const SingleSimCardView: React.FC<CardType> = ({ cardClass, simId }) => {
  const dispatch = useAppDispatch();

  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: simId })
  );
  const screenshot = useSelector((state: RootState) =>
    getScreenshotBySimId(state, { simId: simId })
  );

  const [simulatorState, simulatorStateQueryStatus] = useSimulatorState(simId);
  const [
    simulatorReservations,
    refreshSimulatorReservations,
  ] = useGetCurrentReservations(simulator?.id);
  const [upcomingReservations, setUpcomingReservations] = useState<
    SimulatorReservation[]
  >([]);

  const stateIsActive = simulatorStateIsActive(simulatorState);
  const roundIsActive = useMemo(() => {
    return isActive(simulatorState);
  }, [simulatorState]);
  const simulatorTurnedOff = isSimulatorOff(simulatorState);

  const [roundInProgress, setRoundInProgress] = useState(roundIsActive);

  const simControlModalName = modalName(ModalType.SimControlModal, simId);
  const roundControlModalName = modalName(ModalType.RoundControlModal, simId);
  const scorecardModalName = modalName(ModalType.ScorecardModal, simId);
  const moveRoundModalName = modalName(ModalType.MoveRoundModal, simId);
  const sendSimMessageModalName = modalName(
    ModalType.SendSimulatorMessage,
    simId
  );

  const handleEditSimulatorReservation = (queueItemId: string) => {
    dispatch(
      setSelectedQueueItem({
        selectedQueueItem: queueItemId,
      })
    );
    dispatch(modalAction({ isOpen: true, modalName: ModalType.QueueModal }));

    pendoTrackBasicEvent(ClubhouseEvents.EDIT_RESERVATION, queueItemId);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setUpcomingReservations(simulatorReservations);
    }, 60000);

    return () => clearInterval(interval);
  }, [refreshSimulatorReservations, simulatorReservations]);

  useEffect(() => {
    setRoundInProgress(roundIsActive);
  }, [roundIsActive]);

  return (
    <SimCardContext.Provider value={{ simulatorId: simId }}>
      <motion.div
        className={`${cardClass} ${styles.simCardContainer} relative`}
        variants={containerChildMotionVariants}
        key={`singleSimCard-${simId}`}
      >
        <SimulatorCardOverlay
          simulatorId={simId}
          showOverlay={simulatorTurnedOff}
          showButton={false}
          showIcon={false}
          showLoadingAnimation={
            simulatorStateQueryStatus?.isFinished === true ? false : true
          }
        />
        {isModalOpen(roundControlModalName) && (
          <RoundControlModal
            title="Round Control"
            modalName={roundControlModalName}
            simulatorId={simId}
            buildId={simulator?.caddyVersion}
          />
        )}
        {isModalOpen(scorecardModalName) && (
          <ScorecardModal
            title="View Scorecard"
            modalName={scorecardModalName}
            simulatorId={simId}
          />
        )}
        {isModalOpen(simControlModalName) && (
          <AddTimeInnerModal
            simControlModalName={simControlModalName}
            simulatorState={simulatorState}
            listView={false}
          />
        )}
        {isModalOpen(sendSimMessageModalName) && (
          <SendMessageInnerModal
            simulatorState={simulatorState}
            modalName={sendSimMessageModalName}
          />
        )}
        {isModalOpen(moveRoundModalName) && (
          <MoveRoundInnerModal
            modalName={moveRoundModalName}
            simulatorId={`${simId}`}
            simulatorName={simulator?.name || ""}
            roundId={simulatorState?.roundId}
          />
        )}
        <div
          className={`${stateIsActive ? styles.active : styles.inactive} ${
            styles.simHeaderContainer
          } `}
        >
          <div className={styles.simHeaderNameContainer}>
            <div className={styles.simHeaderName}>
              <div className="flex flex-row truncate">
                <div className="flex flex-col h-10">
                  <span className="brand-subHeading text-sm">
                    {simulator?.name}
                  </span>
                  {stateIsActive && (
                    <ScorecardLinks simId={simulatorState?.id} />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.singleSimCardViewRateOfPlay}>
              {simulatorState?.estimatedTimeRemaining && (
                <RateOfPlay simulatorState={simulatorState} />
              )}
            </div>
          </div>
        </div>
        {roundInProgress ? (
          <RoundProgressBarSlim
            simulatorId={simId}
            secondaryColor="bg-lime-100"
            height="h-0.5"
          />
        ) : (
          <div
            className={`h-0.5 ${
              stateIsActive ? styles.active : styles.inactive
            }`}
          ></div>
        )}
        {screenshot?.image && simulator?.screenShotsOn && stateIsActive ? (
          <LiveScreenshots
            screenshot={screenshot}
            simulatorState={simulatorState}
          />
        ) : (
          <>
            <div
              className={`flex flex-row bg-white ${
                stateIsActive ? styles.active : styles.inactive
              }`}
            >
              <div className={styles.singleSimCardViewBody}>
                <ShutdownAndRestartButtons simulatorId={simulatorState?.id} />
                <div className={styles.singleSimCardViewSimStatus}>
                  <SimStatusCard simulatorState={simulatorState} />
                </div>
                <div className={styles.singleSimCardViewPlayerRow}>
                  <PlayerListIcons
                    showNames="hidden"
                    iconSize="w-7 h-7"
                    roundId={simulatorState?.roundId}
                  />
                </div>
              </div>
              <div className={styles.singleSimCardViewTimerColumn}>
                <SimTimerCard simulatorState={simulatorState} />
              </div>
            </div>

            <div className="flex flex-row h-8 text-xs bg-white dark:bg-zinc-800">
              {upcomingReservations?.length > 0 && (
                <button
                  className="ml-6 flex bg-brandYellow hover:bg-brandGreen dark:text-black text-black hover:text-white rounded-lg h-6 px-4 py-1"
                  onClick={() =>
                    handleEditSimulatorReservation(upcomingReservations[0].id)
                  }
                >
                  Reservation @{" "}
                  {DateTime.fromISO(
                    upcomingReservations[0]?.startTime.toString()
                  ).toLocaleString(DateTime.TIME_SIMPLE)}
                </button>
              )}
            </div>
          </>
        )}

        <div
          className={`${styles.singleSimCardViewFooterRow} ${
            stateIsActive ? styles.active : styles.inactive
          }`}
        >
          {simulator && (
            <SingleSimCardViewFooter
              simulator={simulator}
              roundInProgress={roundInProgress}
              roundIsActive={roundIsActive}
              stateIsActive={stateIsActive}
            />
          )}
        </div>
      </motion.div>
    </SimCardContext.Provider>
  );
};

export default SingleSimCardView;

SingleSimCardView.defaultProps = {
  cardIcon: "",
  cardTitle: "",
  cardStatus: "",
  cardClass: "",
  checked: false,
};
