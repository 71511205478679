import Row from "components/ui/layout/Row";

const SimulatorReservationSkeletonLoader = () => {
  return (
    <div className="flex w-full h-full flex-col">
      <Row cssClasses="pb-2"></Row>
      <Row cssClasses="mb-8">
        <div>
          <div className="flex flex-row w-full items-center gap-2 py-2 rounded pb-6 mb-8 border-b border-gray-100">
            <div className="rounded-full bg-gray-300 w-6 h-6 animate-pulse"></div>
            <div className="flex flex-col gap-2 w-3/4">
              <span className="w-1/4 bg-gray-300 h-2 rounded-full animate-pulse"></span>
              <span className="w-3/4 bg-gray-300 h-2 rounded-full animate-pulse"></span>
            </div>
          </div>
          <div className="flex flex-row w-full items-center gap-2 py-2 rounded mb-8">
            <div className="flex flex-col gap-2 w-full">
              <span className="w-1/4 bg-gray-300 h-2 rounded-full animate-pulse"></span>
              <span className="w-auto bg-gray-300 h-4 rounded-full animate-pulse"></span>
            </div>
          </div>
          <div className="flex flex-row w-full items-center gap-2 py-2 rounded mb-8">
            <div className="flex flex-col gap-2 w-1/2">
              <span className="w-1/4 bg-gray-300 h-2 rounded-full animate-pulse"></span>
              <span className="w-auto bg-gray-300 h-4 rounded-full animate-pulse"></span>
            </div>
            <div className="flex flex-col gap-2 w-1/2">
              <span className="w-1/4 bg-gray-300 h-2 rounded-full animate-pulse"></span>
              <span className="w-auto bg-gray-300 h-4 rounded-full animate-pulse"></span>
            </div>
          </div>
          <div className="flex flex-row w-full items-center gap-2 py-2 rounded mb-8">
            <div className="flex flex-col gap-2 w-full">
              <span className="w-1/4 bg-gray-300 h-2 rounded-full animate-pulse"></span>
              <span className="w-auto bg-gray-300 h-4 rounded-full animate-pulse"></span>
            </div>
          </div>
          <div className="flex flex-row w-full items-center gap-2 py-2 rounded pt-8 pb-8 mb-8 border-b border-gray-100">
            <div className="rounded-xl bg-gray-300 w-40 h-10 animate-pulse"></div>
          </div>
          <div className="flex flex-row w-full items-center gap-2 py-2 rounded mb-8">
            <div className="flex flex-col gap-2 w-full">
              <span className="w-1/4 bg-gray-300 h-2 rounded-full animate-pulse"></span>
              <span className="w-1/4 bg-gray-300 h-2 rounded-full animate-pulse"></span>
              <span className="w-auto bg-gray-300 h-4 rounded-full animate-pulse"></span>
            </div>
          </div>
          <div className="flex flex-row w-full items-center gap-2 py-2 rounded pb-8 mb-8">
            <div className="rounded-xl bg-gray-300 w-full h-10 animate-pulse"></div>
          </div>
        </div>
      </Row>
    </div>
  );
};
export default SimulatorReservationSkeletonLoader;
