import { LatestScreenShotDto, SimulatorState } from "Models";
import Timer from "./timers/Timer";
import SimStatusCard from "./SimStatusCard";
import TimerIconControls from "./timers/TimerIconControls";
import ShutdownAndRestartButtons from "./ShutdownAndRestartButtons";

type OwnProps = {
  screenshot: LatestScreenShotDto;
  simulatorState: SimulatorState;
};

const LiveScreenshots: React.FC<OwnProps> = (props) => {
  const { screenshot, simulatorState } = props;

  return (
    <>
      <div
        className={`flex flex-row h-48`}
        style={{
          backgroundImage: `url(data:image/jpg;base64,${screenshot?.image})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          aspectRatio: `${screenshot?.width} / ${screenshot?.height}`,
          width: "100%",
        }}
      >
        <div className="rounded-br-xl bg-white dark:bg-zinc-800 opacity-50 hover:opacity-95 border border-gray-200 dark:border-zinc-900 p-2 absolute">
          <ShutdownAndRestartButtons simulatorId={simulatorState?.id} />
        </div>
      </div>
      <div className="flex flex-row p-2 bg-white dark:bg-zinc-800">
        <div className="flex w-1/2 text-xs ml-2 items-center justify-items-center">
          <SimStatusCard
            simulatorState={simulatorState}
            hideStatusHeader={true}
          />
        </div>
        <div className="flex w-1/4 text-xs poppins items-center text-center justify-center text-gray-400">
          <Timer simulatorId={simulatorState ? simulatorState.id : ""} />
        </div>
        {simulatorState && (
          <div className="flex w-1/4 text-sm text-gray-400 items-center text-right justify-center">
            <TimerIconControls simulatorState={simulatorState} />
          </div>
        )}
      </div>
    </>
  );
};

export default LiveScreenshots;
