import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const RexReservationsLogoIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      className={`${props.class} fill-current`}
      fill-current={props.class}
      width="109px"
      height="60px"
      viewBox="0 0 109 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon
          id="path-1"
          points="0 0 82.184874 0 82.184874 60 0 60"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="rex_logo_new">
          <g id="Fill-1-Clipped">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="path-1"></g>
            <path
              d="M49.0068808,50.1783044 C39.5638312,46.1789719 34.7711277,36.3391855 32.9371884,31.5661549 C34.6795644,30.5054072 36.2568591,29.2617489 37.6169416,27.8585447 C41.5267611,23.8278371 43.2885191,18.7544059 42.4504142,13.937984 C41.6129776,9.12222965 38.2405077,4.93865154 33.1991798,2.46201602 C28.5100697,0.158277704 22.7502706,-0.562016021 16.9811147,0.441321763 C9.74828282,1.69566088 3.40033996,5.56682244 -0.000200503565,10.7951268 L6.7253574,15.1589453 C8.93290165,11.7657543 13.2791506,9.21435246 18.3532274,8.33317757 C22.4040677,7.62957276 26.524416,8.10954606 29.6589551,9.64959947 C31.2536267,10.4339786 34.0098824,12.2110147 34.5492371,15.3098131 C35.0879233,18.4079439 33.0935812,21.0100802 31.8564742,22.2851135 C29.4270393,24.7911215 25.7097033,26.6309079 21.6588628,27.3338451 C21.0025479,27.4479973 20.348238,27.520761 19.6952646,27.5741656 C20.2259307,22.95 20.6356263,18.3718958 20.7465717,14.5948598 C19.7280136,14.6923231 18.7014354,14.8538718 17.6755254,15.0861816 C15.8883703,15.490721 14.1874317,16.0848464 12.6194939,16.8351802 C11.9371134,28.5828437 8.93156496,46.4353137 8.40557727,49.4967289 L16.5406753,49.4967289 C16.9904716,46.8305073 17.84328,41.6002003 18.6666813,35.6516021 C20.1149855,35.6168893 21.5746514,35.4793724 23.0316439,35.2263685 C23.8797741,35.0788385 24.7098587,34.8865821 25.52925,34.6716289 C27.8718,40.7190254 33.778635,52.3979305 45.8141957,57.5267691 C49.6852511,59.1763017 53.8156246,60.0000668 58.1932857,60.0000668 C65.5270378,60.0000668 73.5531955,57.6763017 82.184874,53.0628171 L73.8091716,48.2330441 C64.2478251,52.4366488 55.9202436,53.1055407 49.0068808,50.1783044"
              id="Fill-1"
              fill="#000000"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            d="M57.4074199,16.6323693 C58.3758114,16.2311664 59.5186337,16.0222205 60.597966,16.0642767 C61.8904914,16.1143437 63.0914574,16.5242248 63.7918538,17.3967243 C64.5283393,18.3126152 64.7836364,19.7178267 64.4260868,20.8900601 C64.1714578,21.7258438 63.3982149,24.2672406 58.4105638,25.9541619 C55.9738795,26.7785972 53.2591751,27.1551005 50.8619215,27.3079715 C51.1793722,22.1664327 54.1032597,18.0001975 57.4074199,16.6323693 L57.4074199,16.6323693 Z M101.424793,31.5396273 C100.20311,33.2198732 98.8891982,34.8387035 97.4449647,36.2138749 C96.0074146,37.5843733 93.6175123,38.6618135 91.5163232,37.9789006 C89.5675102,37.3447197 88.4848364,34.7252185 87.4395884,31.5329518 C88.1393165,30.7692644 88.8410495,29.972199 89.5427825,29.1284046 C94.0753096,23.6804563 98.1132819,17.3613437 101.516353,10.4541116 L94.6794684,6.14902418 C91.7996896,12.1043172 88.4079801,17.6216916 84.6968147,22.3606923 C83.1489922,16.7939185 81.7568875,11.1410297 80.4222581,5.54621848 L73.1770317,9.72513728 C74.7455721,16.237842 76.4143601,22.8166352 78.3451284,29.2779377 C73.7083438,33.5196071 66.9650236,38.1210906 59.3522228,38.3700902 C55.9451419,38.4795698 53.9154627,36.991581 52.7051402,35.2065284 C63.1937098,34.2639354 70.0065351,30.0950299 72.0990362,23.2205083 C73.2264872,19.5148887 72.4405462,15.3620047 70.0473024,12.3826893 C66.0460876,7.40403494 59.097594,7.25984222 54.3358341,9.23247879 C45.0448888,13.0782857 41.4520157,24.4361331 43.3466949,33.1938384 C45.0642701,41.1324489 50.7249164,46.0803957 58.2775688,46.3727864 C58.7166532,46.3894754 59.1624207,46.391478 59.6142032,46.3761242 C63.3380664,46.2546284 71.6906943,45.0276553 81.0905754,37.5042663 C82.5120859,40.8180287 84.7709979,44.2092279 89.0328565,45.5957479 C90.2585503,45.9942805 91.5443924,46.1878726 92.8449377,46.1878726 C96.4391474,46.1878726 100.144298,44.7118999 102.979299,42.0122916 C105.049746,40.0409902 106.813435,37.8093407 108.403361,35.5636726 L101.424793,31.5396273 Z"
            id="Fill-4"
            fill="#000000"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
};
export default RexReservationsLogoIcon;

RexReservationsLogoIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
