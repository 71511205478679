import { Event } from "Models";
import { DateTime } from "luxon";
import { styles } from "./Events.styles";
import { AnimatePresence, motion } from "framer-motion";
import { dailyQueueListItemVariant } from "utils/animations/animationHelper";
import EventItemDetails from "./EventItemDetails";

const EventItem: React.FC<EventItemProps> = (props) => {
  const { event, isOpen, callback } = props;

  return (
    <motion.li
      animate={{ opacity: 1, x: 0 }}
      layout
      variants={dailyQueueListItemVariant}
      onClick={() => callback("")}
      className={styles.eventListItemHighlight}
      key={`eventListItem-${event.id}-${event.name}`}
    >
      <motion.div animate={{ opacity: 1, x: 0 }} layout className="block">
        <div className={styles.eventContainer}>
          <div className={styles.eventContainerTopRow}>
            <p className={styles.eventName}>{event?.name}</p>

            <div className={styles.eventContainerBottomRow}>
              <p className={styles.eventTime}>
                {DateTime.fromISO(event.startDate).toLocaleString(
                  DateTime.DATE_MED
                )}{" "}
                -{" "}
                {DateTime.fromISO(event.endDate).toLocaleString(
                  DateTime.DATE_MED
                )}
              </p>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <p className={styles.eventPlayers}>{event?.description}</p>
            </div>
            <div className={styles.eventOpenItem}>
              <p>
                <button
                  onClick={() => callback("")}
                  className={`hover:text-black hover:underline text-green-900 ${
                    isOpen ? "text-gray-500 dark:text-gray-5" : ""
                  }`}
                >
                  {isOpen ? "Close" : "View Event Details"}
                </button>
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <AnimatePresence>
        {props.isOpen && (
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`py-4 px-6 border-t border-gray-200`}
            key={`selectedEventItem-${event.id}`}
          >
            <EventItemDetails
              event={event}
              key={`selectedEventItemPreview-${event.id}`}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.li>
  );
};
export default EventItem;

type EventItemProps = {
  event: Event;
  isOpen?: boolean;
  callback: React.Dispatch<React.SetStateAction<string>>;
};

EventItem.defaultProps = {};
