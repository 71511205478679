import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { serviceRequestAlerts } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import Alerts from "components/shared/Alerts";
import { AnimatePresence, motion } from "framer-motion";
import { styles } from "components/shared/helpdocs/HelpDocTray.styles";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  useDismissAllServiceRequests,
  useServiceRequestsByFacilityId,
} from "modules/service-requests/serviceRequestHooks";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import { CgCloseR } from "react-icons/cg";

const ServiceRequestTray: React.FC<SlideOverProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const [, dismissAllServiceAlerts] = useDismissAllServiceRequests(
    currentFacilityId
  );
  const [currentFacilityServiceRequests] = useServiceRequestsByFacilityId(
    currentFacilityId
  );
  const isAlertsTrayOpen = useSelector(
    (state: RootState) => state.nav?.serviceRequestAlerts?.isOpen
  );

  const setAlertsTray = (value: boolean) => {
    dispatch(serviceRequestAlerts({ isOpen: value }));
  };

  const handleDismissAllServiceRequests = () => {
    if (window.confirm("Do you want to delete all service requests?")) {
      dismissAllServiceAlerts();
      showCustomNotification(
        "All service alerts deleted",
        GenericNotificationType.SUCCESS
      );
      setAlertsTray(false);
    }
  };

  return (
    <AnimatePresence>
      {isAlertsTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className={styles.trayContainer}
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => setAlertsTray(false)}
                  className="text-gray-500 dark:text-white"
                >
                  <CgCloseR className="h-5 w-5" /> Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>Service Requests</h2>
              </div>
              <div className={styles.headerRightButton}>
                {currentFacilityServiceRequests.length > 0 ? (
                  <button
                    onClick={() => handleDismissAllServiceRequests()}
                    className={styles.button}
                  >
                    Dismiss All
                  </button>
                ) : null}
              </div>
            </div>
            <div className={styles.innerContainer}>
              <Alerts />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setAlertsTray(false)}
            className={styles.overlay}
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default ServiceRequestTray;

type SlideOverProps = {
  title?: string;
  body?: React.ReactNode;
};

ServiceRequestTray.defaultProps = {
  title: "Service Requests",
};
