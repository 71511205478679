import { useEffect, useState } from "react";
import { updateEntities } from "redux-query";
import { useAppDispatch } from "utils/hooks";
import { logoutUser } from "modules/user/userHooks";
import { useLocation, useHistory } from "react-router-dom";
import { setIsAuthenticated } from "modules/user/userSlice";

const InactivityLogoutTimer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useHistory();
  const [timeExpired, setTimeExpired] = useState(false);

  const updateExpiryTime = () => {
    const expireTime = Date.now() + 3 * 60 * 60 * 1000;
    localStorage.setItem("aGClubhouseExpireTime", expireTime.toString());
  };

  useEffect(() => {
    const useHandleSignOut = async (enabled?: boolean) => {
      if (enabled) {
        await logoutUser(true);
        await dispatch(
          updateEntities({
            facilities: () => undefined,
            simulators: () => undefined,
          })
        );
        await dispatch(setIsAuthenticated({ isAuthenticated: false }));
      }
    };

    const useCheckForInactivity = () => {
      const expireTime = parseInt(
        localStorage.getItem("aGClubhouseExpireTime") || "0"
      );

      if (expireTime < Date.now() && location.pathname !== "/") {
        localStorage.removeItem("token");
        setTimeExpired(true);
      }
      useHandleSignOut(timeExpired);
    };

    const interval = setInterval(useCheckForInactivity, 60000);
    return () => clearInterval(interval);
  }, [dispatch, location.pathname, navigate, timeExpired]);

  useEffect(() => {
    const events = ["click", "keypress", "scroll", "mousemove"];
    events.forEach((event) => window.addEventListener(event, updateExpiryTime));
    return () =>
      events.forEach((event) =>
        window.removeEventListener(event, updateExpiryTime)
      );
  }, []);

  return null;
};

export default InactivityLogoutTimer;
