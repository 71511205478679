import { styles } from "./Events.styles";

const EventItemSkeletonLoader = () => {
  return (
    <div className="my-2 py-2 bg-white dark:bg-zinc-800 dark:text-gray-100 rounded border border-gray-200 w-full">
      <div className="block">
        <div className={styles.eventContainer}>
          <div className={`${styles.eventContainerTopRow} flex-row`}>
            <div className="flex w-1/4 bg-gray-300 h-2 rounded-full animate-pulse"></div>

            <div className="flex flex-row ml-2 justify-between">
              <div className="flex w-12 bg-gray-300 h-2 rounded-full animate-pulse mr-2"></div>
              <div className="flex w-12 bg-gray-300 h-2 rounded-full animate-pulse pr-1"></div>
            </div>
          </div>
          <div className="mt-2 flex flex-row justify-between">
            <div className="flex w-1/2">
              <div className="w-5/6 bg-gray-300 h-2 rounded-full animate-pulse"></div>
            </div>
            <div className={styles.eventOpenItem}>
              <button className="rounded-xl bg-gray-300 w-40 h-2 animate-pulse"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventItemSkeletonLoader;
