import Menu from "./Menu";
import Logo from "./Logo";
import { useAppDispatch } from "utils/hooks";
import { collapseSidebarAction } from "ui-modules/nav/navSlice";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { styles } from "./DesktopSidebar.styles";
import { AnimatePresence, motion } from "framer-motion";
import {
  BreakPoints,
  desktopSidebarVariants,
  desktopSidebarVariantsClosed,
  useMediaQuery,
} from "utils/animations/animationHelper";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import LogoutButton from "./LogoutButton";

const DesktopSidebar = () => {
  const dispatch = useAppDispatch();

  const isSidebarCollapsed = useSidebarCollapsed();

  const setCollapseSidebar = () =>
    dispatch(collapseSidebarAction({ isOpen: !isSidebarCollapsed }));

  const isDesktop = useMediaQuery(BreakPoints.LG);

  return (
    <AnimatePresence>
      <motion.div
        variants={
          isDesktop ? desktopSidebarVariants : desktopSidebarVariantsClosed
        }
        animate={!isSidebarCollapsed ? "open" : "closed"}
        className={styles.desktopSidebar}
        id="desktopSidebar"
      >
        <div
          className={styles.desktopSidebarMenuPanel}
          id="desktopSidebarMenuPanel"
        >
          <div className={styles.desktopSidebarLogo}>
            <Logo logoSize="logo-medium" />
          </div>
          <div className="flex flex-row h-full overflow-x-hidden overflow-y-hidden marginTop12">
            <nav
              className={`${styles.desktopSidebarNav} ${
                isSidebarCollapsed ? "px-5" : "px-8"
              } `}
            >
              <ul className={styles.desktopSidebarList} id="mainMenu">
                <li>
                  <Menu />
                </li>
              </ul>
              <LogoutButton />
              <div className={styles.desktopSidebarCollapseButton}>
                <button onClick={setCollapseSidebar}>
                  {isSidebarCollapsed ? (
                    <FiChevronsRight className="mb-1" />
                  ) : (
                    <FiChevronsLeft className="mb-1" />
                  )}
                </button>
              </div>
            </nav>
            <div className="flex sidebarCurve background-gray text-transparent dark:bg-zinc-900 rounded-tl-2xl border-l border-t border-zinc-200 dark:border-gray-900 m-0"></div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
export default DesktopSidebar;
